import React, { useState, useEffect, lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import LoadingScreen from './container/Loading';
import Header from './container/Header';
import './container/css/styles.css';

const MainPage = lazy(() => import('./container/MainPage'));
const SignInPage = lazy(() => import('./container/SignInPage'));
const Dashboard = lazy(() => import('./container/Dashboard'));
const Calendar = lazy(() => import('./container/Calendar'));
const Monthly = lazy(() => import('./container/Monthly'));
const SignUp = lazy(() => import('./container/SignUp'));
const Weekly = lazy(() => import('./container/Weekly'));
const Profile = lazy(() => import('./container/Profile'));
const AdminSettings = lazy(() => import('./container/AdminSettings'));
const ForgottenPassword = lazy(() => import('./container/ForgottenPassword'));
const Salaries = lazy(() => import('./container/Salary'));
const Subscribe = lazy(() => import('./container/Subscribe'));

function App() {
  const [isNightMode, setIsNightMode] = useState(() => {
    const storedValue = localStorage.getItem('isNightMode');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('offline', updateOnlineStatus);
    window.addEventListener('online', updateOnlineStatus);

    return () => {
      window.removeEventListener('offline', updateOnlineStatus);
      window.removeEventListener('online', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const updateLastInteractionTime = () => {
      localStorage.setItem('lastInteractionTime', new Date().toISOString());
    };

    const checkLastInteractionTime = () => {
      const lastInteractionTime = localStorage.getItem('lastInteractionTime');
      if (lastInteractionTime) {
        const timeDifference = (Date.now() - new Date(lastInteractionTime).getTime()) / 1000 / 60;
        if (timeDifference > 5) {
          window.location.reload();
        }
      }
    };

    updateLastInteractionTime();

    const events = ['click', 'keydown', 'mousemove', 'touchstart'];
    events.forEach(event => window.addEventListener(event, updateLastInteractionTime));

    checkLastInteractionTime();

    return () => {
      events.forEach(event => window.removeEventListener(event, updateLastInteractionTime));
    };
  }, []);

  const toggleNightMode = () => {
    const newMode = !isNightMode;
    setIsNightMode(newMode);
    localStorage.setItem('isNightMode', JSON.stringify(newMode));
  };


  useEffect(() => {
    const interval = setInterval(() => {
      console.log("check-webview");
    }, 1000); // Every second
  
    return () => clearInterval(interval); // Cleanup the interval when the component unmounts
  }, []);
  

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <div className={`App ${isNightMode ? 'night' : 'day'}`}>
          <Header toggleNightMode={toggleNightMode} isNightMode={isNightMode} />
          {!isOnline ? (
            <LoadingScreen />
          ) : (
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/signin" element={<SignInPage />} />
                <Route path="/confirmation" element={<SignInPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/monthly" element={<Monthly />} />
                <Route path="/weekly" element={<Weekly />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/admin-settings" element={<AdminSettings />} />
                <Route path="/forgot-password" element={<ForgottenPassword />} />
                <Route path="/salaries" element={<Salaries />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/subscribe" element={<Subscribe />} />
              </Routes>
            </Suspense>
          )}
        </div>
      </I18nextProvider>
    </Router>
  );
}

export default App;
